import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const SectionWrap = styled.section`
  background: #f4f8fa;
  min-height: 100vh;
  padding-top: 50px;
  width: 100%;
`;

const FormBox = styled.div`
  padding: 40px;
  background: #fff;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 4px 8px 0 rgba(53, 105, 128, 0.3),
    0 6px 20px 0 rgba(165, 200, 213, 0.41);
`;

declare global {
  interface Window {
    hbspt: any;
  }
}

class HowItWorksForm extends React.Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "2004091",
          formId: "e0b737c8-26fd-45bd-ae55-1293b719d9bb",
          target: "#hubspotForm",
        });
      }
    });
  }

  render() {
    return (
      <SectionWrap>
        <Container>
          <FormBox id="hubspotForm"></FormBox>
        </Container>
      </SectionWrap>
    );
  }
}

export default HowItWorksForm;
